<template>
    <div class="main-footer" id="aboutUs">
        <div class="footer-content">
            <div class="service-area">
                <!-- <p class="footer-sub-title">服务与支持</p> -->
                <ul>
                    <li class="service-item"> 北京首版科技有限公司 </li>
                    <li class="service-item"> 电话：010-57188208 </li>
                    <li class="service-item"> 邮箱：zhangyu@sdbq.org </li>
                    <li class="service-item">地址：北京市朝阳区东进国际A座12层首都版权协会 </li>
                    <li class="service-item">友情链接：<a  target="_blank" href="http://qgzpdj.ccopyright.com.cn">全国作品登记信息数据库管理平台</a> </li>
                </ul>
            </div>
            <!-- <div class="connect-phone">
                <p class="footer-sub-title">客服电话</p>
                <p class="phone">010-57188208</p>
            </div> -->
            <div class="qr-code">
                <img src="~@/assets/images/weichat.png" alt="">
                <p class="wechat-text">北京首版科技有限公司 <a href="https://beian.miit.gov.cn" target="_blank" >京ICP备19027161号</a></p>
            </div>
        </div>
        <!-- <div class="line"></div>
        <div class="footer-filing">
            <p>Copyright（©）2023 北京首版科技有限公司 版权所有 
                <a href="https://beian.miit.gov.cn/#/Integrated/index">京ICP备19060018号-1</a>
            </p>
        </div> -->
    </div>
</template>

<script>
    export default {
        data () {
            return {
            }
        },
        mounted() {
            this.init();
        },
        methods: {

            init(){

            },

        },
        computed: {

        },
        watch: {

        }
    }
</script>

<style lang="less">
.main-footer{
    // position: absolute;
    // bottom: 0;
    // flex-shrink: 0;
    background-color: #060F3C;
    width:100%;
    // height:3.27rem;
    height:2.9rem;
    // padding-top:0.8rem;
    .footer-content{
        height:2.66rem;
        width:12rem;
        margin:0 auto;
        display: flex;
        justify-content: space-between;
        text-align: left;
        .footer-sub-title{
            font-size: 18px;
            color: #FFFFFF;
            margin-bottom:17px;
        }
        .service-area{
            margin-top:0.65rem;
            .service-item{
                font-size: 14px;
                // font-family: 'Source Han Sans CN';
                font-weight: 300;
                color: #FFFFFF;
                line-height: 30px;
                margin-top: 0.1rem
            }
            .service-item:first-child{
                margin-top:0;
            }
        }
        .connect-phone{
            margin-top:0.93rem;
            .phone{
                font-size: 30px;
                // font-family: 'Source Han Sans CN';
                font-weight: 500;
                color: #FFFFFF;
            }
        }
        .qr-code{
            margin-top:0.7rem;
            text-align:center;
            img{
                width:1.07rem;
                height:1.25rem;
            }
            .wechat-text{
                font-size: 14px;
                // font-family: 'Source Han Sans CN';
                font-weight: 300;
                color: #FFFFFF;
                text-align: center;
                margin-top:15px;
                a:hover{
                    text-decoration:underline;
                }
            }
        }

    }
    .line{
        width:100%;
        height: 1px;
        background: #E7E9EE;
        opacity: 0.14;
    }
    .footer-filing{
        height: 60px;
        text-align: center;
        background-color: #060F3C;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content:center;
        // border-top: 1px solid #E7E9EE;
        p{
            font-family: 'Adobe Heiti Std';
            font-weight: normal;
            color: #FFFFFF;
            line-height: 24px;
            opacity: 0.12;
            font-size: 12px;
        }
        a{
            color:#fff;
            text-decoration: none;
            &:hover{
                text-decoration: underline;
            }
        }
    }




    
}
 
</style>;