<template>
    <div  class="main-header">
        <a href="/" class="logo-home">
            <div class="app-logo">
                <img src="../../assets/images/logo-light.png" alt="logo">
                <span class="logo-title">北京市版权业务社会服务体系</span>
            </div>
        </a>
        <div class="app-menu">
            <el-menu
                :default-active="$route.name"
                class="el-menu-demo"
                mode="horizontal"
                :router="true"
                @select="handleSelect"
                text-color="#333 "
                active-text-color="#0960BD">
                <el-menu-item index="home">
                    <a href="#/home">首页</a>
                </el-menu-item>
                <el-menu-item index="register">
                    <a href="#/register">版权登记</a>
                </el-menu-item>
                <el-menu-item index="query">
                    <a href="#/query">版权查询</a>
                </el-menu-item>
                <el-menu-item index="adjust">
                    <a href="#/adjust">版权调解</a>
                </el-menu-item>
                <el-menu-item index="study">
                    <a href="#/study">版权学习</a>
                </el-menu-item>
                <el-menu-item index="active">
                    <a href="#/active">版权活动</a>
                </el-menu-item>
            </el-menu>
        </div>
        <div class="user-option">
            <a href="http://home.bqshfw.com" target="_blank"><el-button type="text">登录</el-button></a>
            <a href="http://home.bqshfw.com/#/register"  target="_blank"><el-button type="primary">注册</el-button></a>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                activiti:this.$route.name,
            }
        },
        mounted() {
            this.init();
            console.log(this.$route)
        },
        methods: {

            init(){

            },
            handleSelect(val){
                console.log(val,'---菜单--')

            },
            edit(){

            },
        },
        computed: {

        },
        watch: {

        }
    }
</script>

<style lang="less">

    .main-header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 88px;
        padding: 0 50px;
        box-sizing: border-box;
        margin:0 auto;
        background: #fff;
        box-shadow:0 0 20px 2px rgb(0 0 0 / 20%);
        .logo-home{
            text-decoration: none;
            display: flex;
            align-items: center;
        }
        .el-menu.el-menu--horizontal{
            border:none;
            background-color: rgba(250,250,250,0);
        }
    
        .app-logo{
            display: flex;
            align-items: center;
            img{
                // width: 160px;
                // height: 48px;
            }
            .logo-title{
                font-size: 20px;
                color: #333333;
                font-weight: bold;
                margin-left:10px;
            }
        }
        .app-menu{
            .el-menu--horizontal>.el-menu-item:not(.is-disabled):focus, .el-menu--horizontal>.el-menu-item:not(.is-disabled):hover, .el-menu--horizontal>.el-submenu .el-submenu__title:hover{
                background-color: rgba(250,250,250,0);
                color: #0960bd!important;
            }
            .el-menu--horizontal>.el-menu-item.is-active{
                color: #0960BD;
                // border-bottom: none;
            }
            .el-menu-item{
                font-size: 18px;
                font-weight: 500;
                &:hover{
                    color: #0960BD;
                }
                a{
                    text-decoration: none;
                }
            }
            .el-menu--horizontal>.el-menu-item{
                height: 100%;
                line-height: 70px;
            }
            // .el-menu--horizontal>.el-menu-item:last-child{
            //     padding-right:0;
            // }
            .el-button {
                width: 112px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 10px;
            }
            .el-button+.el-button, .el-checkbox.is-bordered+.el-checkbox.is-bordered{
                margin-left:23px;
            }
        }
        .user-option{
            display: flex;
            align-items: center;
            .el-button--primary{
                width:94px;
                // height:38px;
                padding:10px 20px;
                font-size: 18px;
                font-family: 'Source Han Sans CN';
                font-weight: 500;
                color: #FFFFFF;
                border:none;
                span{
                    line-height: 18px;
                }
            }
            .el-button--text{
                margin-right: 33px;
                font-size: 18px;
                font-family: 'Source Han Sans CN';
                font-weight: 500;
                color: #333333;
                padding: 0;
            }
        }
    } 
    .el-header{
        margin:0;
        padding: 0;
    }
    
 
</style>